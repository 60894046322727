
import { defineComponent, ref, Ref, computed, onMounted, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useField, useForm } from "vee-validate";
import {
  RegisterSpecialization,
  Specializations,
  SpecializationsStatus,
  VisitType,
} from "../../types";
import { useStore } from "../store";
import { Tag } from "@hd2/common/types";
import { map } from "lodash";
import { OptionCoreData } from "ant-design-vue/lib/vc-select/interface";
import { InfoCircleFilled } from "@ant-design/icons-vue";
import * as yup from "yup";
import Tags from "@hd2/common/src/components/Tags.vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { useRouter } from "vue-router";
import { visitTypesIconMap } from "../utils/const";
import { AxiosStatic } from "axios";

type RegisterSpecializationExt = Omit<
  RegisterSpecialization,
  "consultationTypes"
> & {
  consultationTypes: Set<VisitType>;
};

export const RegisterSpecializationsComponent = defineComponent({
  components: {
    InfoCircleFilled,
    Tags,
  },
  setup() {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const store = useStore();
    const { showErrors } = useShowErrors();
    const router = useRouter();

    const schema = yup.object({
      consultationIds: yup.array().min(1, t("REGISTER.FIELD_REQUIRED")),
      consultationTypes: yup
        .mixed()
        .test(
          "min",
          t("REGISTER.FIELD_REQUIRED"),
          async (value: Set<VisitType>) => {
            return value ? value.size > 0 : false;
          }
        ),
    });

    const { errors, meta } = useForm({
      validationSchema: schema,
      initialValues: {
        consultationIds: [],
        consultationTypes: new Set([]),
      },
    });

    const model: Ref<RegisterSpecializationExt> = ref({
      consultationIds:
        useField<RegisterSpecializationExt["consultationIds"]>(
          "consultationIds"
        ).value,
      consultationTypes:
        useField<RegisterSpecializationExt["consultationTypes"]>(
          "consultationTypes"
        ).value,
    });

    const loading: Ref<boolean> = ref(true);
    const submitLoading: Ref<boolean> = ref(false);
    const visitSpecializations: Ref<Array<OptionCoreData>> = ref([]);
    const visitTypes: Ref<Array<Tag>> = ref([]);
    const specializations: Ref<Specializations> = ref({});

    const prev = () => {
      router.push("/register/contract");
    };

    const submit = async () => {
      const reqBody: RegisterSpecialization = {
        consultationIds: model.value.consultationIds,
        consultationTypes: Array.from(model.value.consultationTypes),
      };
      submitLoading.value = true;
      try {
        await http.put(
          `v1/doctors/${store.state.user.id}/register/specializations`,
          reqBody
        );
        store.commit("setUser", {
          ...store.state.user,
          registerStatus: "WAITING_FOR_ACCEPT",
        });
        router.push("/register/waiting-for-accept");
      } catch (e: any) {
        showErrors(e.response?.data);
      } finally {
        submitLoading.value = false;
      }
    };

    onMounted(async () => {
      const responses = await Promise.all([
        http
          .get("v1/doctors/dictionary/specializations")
          .then((res): Specializations => res.data),
        http
          .get("v1/doctors/dictionary/specialization-statuses")
          .then((res): Array<SpecializationsStatus> => res.data),
        http.get("v1/visits/types").then((res): Array<VisitType> => res.data),
        http
          .get("v1/doctors/dictionary/official-specializations")
          .then((res): Specializations => res.data),
      ]);
      visitTypes.value = map(responses[2], (value) => {
        return {
          id: value,
          name: t(`VISIT_TYPE.${value}`),
          icon: visitTypesIconMap.get(value),
          disabled: false,
        };
      });

      specializations.value = responses[3];

      visitSpecializations.value = map(responses[0], (value, key) => {
        return {
          title: t(`SPECIALIZATION.${key}`),
          value: value,
          key: key,
        };
      }).sort((a, b) => a.title.localeCompare(b.title));

      try {
        if (store.state.user.registerStatus !== "SPECIALIZATIONS") {
          const data = await http
            .get(`v1/doctors/${store.state.user.id}/register/specializations`)
            .then((res): RegisterSpecialization => res.data);

          model.value.consultationIds = data.consultationIds;
          model.value.consultationTypes = new Set(data.consultationTypes);
        }
      } finally {
        loading.value = false;
      }
    });

    return {
      errors,
      t,
      prev,
      isValid: computed(() => meta.value.valid && meta.value.dirty),
      loading,
      submitLoading,
      submit,
      visitSpecializations,
      specializations,
      model,
      visitTypes,
    };
  },
});
export default RegisterSpecializationsComponent;
